import React from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import {
  Button,
  ButtonProps,
  CircularProgress
} from '@mui/material';

const useStyles: Function = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.common.white,
      fontWeight: 700,
      background: theme.palette.primary.main,
      borderRadius: 16,
      padding: '10px 30px',
      boxShadow: '0px 0px 16px 0px rgba(150, 150, 150, 0.15)',
    }
}));


interface Props {
  onPress: () => void;
  loading?: boolean;
}

export const YButtonPrimary: React.FC<Props & ButtonProps> = props => {

  const classes = useStyles();
  const {  children, onPress, loading, ...rest} = props;

  return (
    <Button
      className={classes.root}
      size="large"
      variant="contained"
      color="primary"
      onClick={loading ? undefined : onPress}
      endIcon={loading ? <CircularProgress size={24} sx={{color: '#ffffff'}}/> : null}
      {...rest}
    >
      {children}
    </Button>
  );
}

export default (YButtonPrimary);
