import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import YGrid from 'components/common/YGrid';
import { useRootProvider } from 'providers/RootContext';
import {
  Box, Button,
  Chip,
  ChipProps
} from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import {YCard} from "../../components/common";

const Orders = observer(() => {
  const { orderProvider, userProvider } = useRootProvider();

  const status = (params: GridRenderCellParams) => {
    let color;

    switch (params.row.status) {
      case 'open':
        color = '#007bff'; // Blue
        break;
      case 'delivered':
        color = '#28a745'; // Green
        break;
      case 'failed':
        color = '#dc3545'; // Red
        break;
      case 'confirmed':
        color = '#ffc107'; // Yellow
        break;
      case 'pending':
        color = '#17a2b8'; // Cyan
        break;
      case 'canceled':
        color = '#6c757d'; // Grey
        break;
      default:
        color = '#343a40'; // Darker Grey
    }

    return <Chip label={params.row.status.toUpperCase()} style={{background: color, color: 'white'}} />
  }

  let columns = [
    { field: 'reference', headerName: 'Reference', flex: 0.4,
      renderCell: (params: GridRenderCellParams) => (
          <Button
              color="primary"
              sx={{textTransform: 'none'}}
              href={`/orders/${params.row.id}/details`}
          >{params.row.reference}
          </Button>
    )},
    { field: 'createdAt', headerName: 'Date', type: 'dateTime', flex: 0.3,
      valueGetter: (params: GridRenderCellParams<string>) =>
        params.row.createdAt ? new Date(params.row.createdAt) : null
    },
    { field: 'type', headerName: 'Type' },
    { field: 'totalAmount', headerName: 'Amount', flex: 0.3,
      valueGetter: (params: any) => {
        return params.row.totalAmount.formatted;
      }},
    { field: 'status', headerName: 'Status', flex: 0.3, renderCell: status },
  ];

  // if user is admin, insert account column
  if (userProvider.isAdmin) {
    columns = [
      ...columns.slice(0, 2),
      { field: 'accountId', headerName: 'Account' },
      ...columns.slice(2)
    ]
  }

  useEffect(() => {
    orderProvider.getOrders();
  }, [orderProvider]);

  return (
    <Box sx={{width: '100%', height: '100%'}}>
      <YCard>
        <YGrid
          columns={columns}
          rows={orderProvider.orders}
        />
      </YCard>
    </Box>
  );
})

export default Orders;

