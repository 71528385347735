const difficulties = [
  {
    id: '25x900',
    label: 'Newbie',
    pieces: 25,
    duration: 900,
    hint: '(25 pieces 15 min) 95% of players will complete this game',
  },
  {
    id: '45x720',
    label: 'Intermediate',
    pieces: 45,
    duration: 720,
    hint: '(49 pieces 12 min) 75% of players will complete this game',
  },
  {
    id: '81x600',
    label: 'Advanced',
    pieces: 81,
    duration: 600,
    hint: '(81 pieces 10 min) 50% of players will complete this game',
  },
  {
    id: '144x480',
    label: 'Expert',
    pieces: 144,
    duration: 480,
    hint: '(144 pieces 8 min) 10% of players will complete this game',
  }
];


export default difficulties;
