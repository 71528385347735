import React, {useEffect} from 'react';
import { useForm } from 'react-hook-form';
import { useRootProvider } from 'providers/RootContext';
import { observer } from 'mobx-react-lite';
//import { useRootProvider } from 'providers/RootContext';
import {
  Box,
  MenuItem,
  InputAdornment,
  Skeleton,
} from '@mui/material';
import { useWatch } from 'react-hook-form';
import {
  YTextField,
  SpacerV,
  YButtonPrimary, YAutocomplete,
} from 'components/common';
import {toJS} from "mobx";

const CreateReward = observer(() => {
  const { campaignProvider: cp, wizardProvider: wp } = useRootProvider();
  const form = useForm({mode: 'onChange'});

  const typeValue = useWatch({
    control: form.control,
    name: 'type'
  });

  const [searchWord, setSearchWord] = React.useState('');

  const rewardTypes = [
    {label: 'Item Reward', value: 'free_item'},
    {label: 'Percent Off', value: 'percent'},
    {label: 'Amount Off', value: 'dollar_amounts'},
  ];

  useEffect(() => {
    cp.getRewardForCurrentCampaign().then((res: any) => {
      console.log('getting reward');
      console.log('reward res', toJS(res));
      if (res) {
        /*for (const [key, value] of Object.entries(res)) {
          form.setValue(key, value);
        }*/
        form.setValue('name', res.name);
        form.setValue('type', res.type);
        if (res.type === 'free_item'){
          form.setValue('freeItems', res.freeItems);
        }
        if (res.type === 'dollar_amounts'){
          form.setValue('amount', res.amount?.value);
        }
        if (res.type === 'percent'){
          form.setValue('percent', res.percent?.value);
        }
        form.setValue('minOrderAmount', res.minOrderAmount?.value);
        form.setValue('duration', res.duration);
      }
    });
  }, [cp, form]);

  useEffect(() => {
    console.log('useEffect called', searchWord, typeValue);
    if (typeValue === 'free_item'){
      cp.getItems(searchWord);
    }
  }, [searchWord, typeValue]);

  useEffect(() => {
    if (form.formState.isValid) {
      wp.setOnNext(() => {
        form.handleSubmit(cp.saveReward)();
      });
    } else {
      wp.setOnNext(undefined);
    }
  }, [cp, searchWord, form, form.formState?.isValid]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
    >
      <div>
        {cp.fetching && (
            <div>
              <Skeleton variant="rectangular" sx={{borderRadius: '16px'}} height={40} width={300} />
              <Skeleton variant="text" sx={{ fontSize: '1rem'  }} />
              <SpacerV />
              <Skeleton variant="rectangular" sx={{borderRadius: '16px'}} height={40} width={300} />
              <Skeleton variant="text" sx={{ fontSize: '1rem'  }} />
              <SpacerV />
              <Skeleton variant="rectangular" sx={{borderRadius: '16px'}} height={40} width={300} />
              <Skeleton variant="text" sx={{ fontSize: '1rem'  }} />
              <SpacerV />
              <Skeleton variant="rectangular" sx={{borderRadius: '16px'}} height={40} width={300} />
              <Skeleton variant="text" sx={{ fontSize: '1rem'  }} />
              <SpacerV />
            </div>
          )
        }

        {!cp.fetching && (
          <div>
            <YTextField
              fullWidth
              name="name"
              label="Reward Name"
              control={form.control}
              helperText="If the reward is an item from your menu, the name should match"
              isRequired
            />

            <YTextField
              fullWidth
              select
              name="type"
              label="Reward Type"
              control={form.control}
              helperText="What kind of reward would you like to offer"
              isRequired
              onChange={(e: any) => {
                const selectedType = e.target.value;
                rewardTypes.forEach(r => {
                  console.log('r', r, selectedType);
                  if (r.value !== selectedType) {
                    form.setValue(r.value, undefined);
                  } else {
                    form.setValue('type', r.value);
                  }

                  if (r.value !== 'free_item'){
                    form.setValue('freeItems', undefined);
                  }

                  form.setValue('number', undefined);
                });
              }}
            >
              {rewardTypes.map(r => (
                <MenuItem key={r.label} value={r.value}>{r.label}</MenuItem>
              ))}
            </YTextField>

            {typeValue === 'percent' &&
              <YTextField
                fullWidth
                type="number"
                name="percent"
                label="Percent"
                control={form.control}
                helperText={`Enter percent value`}
                InputProps={{
                  startAdornment: <InputAdornment  position="start">%</InputAdornment>
                }}
                isRequired
              />
            }

            {typeValue === 'dollar_amounts' &&
              <YTextField
                fullWidth
                type="amount"
                name="amount"
                label="Amount"
                control={form.control}
                helperText={`Enter amount value`}
                InputProps={{
                  startAdornment: <InputAdornment  position="start">$</InputAdornment>
                }}
                isRequired
              />
            }

            {typeValue === 'free_item' && (
              <YAutocomplete
                options={cp.items}
                name="freeItems"
                label="Free items"
                control={form.control}
                helperText="Select free items"
                isRequired
                inputValue={searchWord}
                // @ts-ignore
                onChange={(e: any, data: any) => {
                  if (e?.type === 'click'){
                    setSearchWord('');
                  }
                }}
                onInputChange={(event: any, newInputValue: any) => {
                  if (event?.type === 'change') {
                    setSearchWord(newInputValue);
                  }
                }}
              />
            )}

            <YTextField
              fullWidth
              name="minOrderAmount"
              type="number"
              label="Min Order Amount"
              control={form.control}
              helperText="The lowest order amount to redeem the reward"
              inputProps={{ step: '0.01' }}
              InputProps={{
                startAdornment: <InputAdornment  position="start">$</InputAdornment>
              }}
              isRequired
            />

            <YTextField
              fullWidth
              name="duration"
              type="number"
              label="Duration"
              control={form.control}
              helperText="Duration of the reward"
              inputProps={{ step: 1 }}
              isRequired
            />
          </div>
        )}
      </div>
    </Box>
  );
})

export default CreateReward;

