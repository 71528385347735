import React, {useEffect, useState} from 'react';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import { useRootProvider } from 'providers/RootContext';
import { v4 as uuidv4 } from 'uuid';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Typography
} from '@mui/material';
import {
  Delete
} from '@mui/icons-material';
import {
  YTextField,
  YButtonPrimary,
} from 'components/common';
import {toJS} from "mobx";

const FinePrint = observer(() => {
  const { campaignProvider: cp, wizardProvider: wp } = useRootProvider();

  const form = useForm({mode: 'onChange'});
  const [reward, setReward] = useState<any>();

  useEffect(() => {
    cp.setFinePrint(cp.ctx.campaign.finePrint);
  }, [cp, form]);

  useEffect(() => {
    cp.getRewardForCurrentCampaign().then((res: any) => {
      console.log('reward res: ', toJS(res));
      setReward(res);
    });
  }, [cp, form]);

  useEffect(() => {
    console.log('fine print form isValid:', form.formState.isValid);
    if (cp.finePrint.length !== 0) {
      wp.setOnNext(() => {
        form.handleSubmit(cp.saveFinePrint)();
      });
    } else {
      wp.setOnNext(undefined);
    }
  }, [cp, form, cp.finePrint]);

  const onEnter = (value: string) => {
    cp.addFinePrint(value);
    form.reset({data: ''})
  }

  const getMinOrderAmount = () => {
    return reward?.minOrderAmount?.formatted;
  }

  const getRewardDuration = () => {
    return reward?.duration;
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
    >
      <div>
        <Typography variant="caption">
          Every campaign will have a visible fine print section. <br/>
          You can use this to explain conditions and circumstances under which this reward applies.<br/>
        </Typography>

        {getMinOrderAmount() && (
          <Typography variant={"caption"}>
            Minimum order amount <strong>{getMinOrderAmount()}</strong>. <br/>
          </Typography>
        )}

        {getRewardDuration() && (
          <Typography variant={"caption"}>
            Reward expires in <strong>{getRewardDuration()} days</strong> from the day you receive it.
          </Typography>
        )}

        <List dense style={{marginTop: '2em'}}>
          {
            cp.finePrint.map((fp: string) => (
              <ListItem
              key={uuidv4()}
              secondaryAction={
                <IconButton edge="end" aria-label="delete" onClick={cp.removeFinePrint(fp)}>
                  <Delete />
                </IconButton>
              }
              >
                <ListItemText primary={fp} />
              </ListItem>
            ))
          }
        </List>

        <YTextField
          fullWidth
          name="condition"
          label="Condition"
          control={form.control}
          onEnter={onEnter}
          helperText="Enter one condition per line and press 'Enter'"
        />
      </div>
    </Box>
  );
})

export default FinePrint;
