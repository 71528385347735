import {makeAutoObservable} from 'mobx';
import RootProvider from './RootProvider';

export interface WizardDefaults {

}

export default class WizardProvider {
  root: RootProvider;
  currentStep: number = 0;
  onNext?: () => void;
  defaults: WizardDefaults = {
  };

  constructor(root: RootProvider) {
    this.root = root;
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setDefaults(key: keyof WizardDefaults, value: any) {
    if (this.defaults[key]) {
      // TODO
    }
  }

  setOnNext(onNext?: () => void) {
    this.onNext = onNext;
  }

  setCurrentStep(step: number) {
    this.currentStep = step;
  }

  nextStep() {
    this.currentStep += 1;
    if (this.onNext) {
      this.onNext();
      this.onNext = undefined;
    }
  }

  prevStep() {
    if (this.currentStep > 0) {
      this.currentStep -= 1;
    }
  }

  reset() {
    this.currentStep = 0;
  }
}
