import React, {useEffect, useState} from 'react';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import { useRootProvider } from 'providers/RootContext';
import difficulties from 'data/gameDifficulties';
import {
  Box,
  Grid,
  MenuItem,
  IconButton,
  Typography,
  Skeleton,
} from '@mui/material';
import { Delete } from '@mui/icons-material';
import {
  YTextField,
  SpacerV,
  Uploader,
  YButtonPrimary
} from 'components/common';
import {toJS} from "mobx";


const SetupGame = observer(() => {
  const { campaignProvider: cp,  wizardProvider: wp } = useRootProvider();

  const form = useForm({mode: 'onChange'});

  const [useOnlineImage, setUseOnlineImage] = useState(false);

  const onImageUpload = (file: File) => {
    cp.setGameImage(file);
  }

  const onImageRemove = () => {
    setUseOnlineImage(false);
  }

  useEffect(() => {
    if (cp.ctx.isEditing) {
      cp.getGameForCampaign().then((res: any) => {
        console.log('getGameForCampaign res', toJS(res));
        form.reset(cp.defaults.game.defaultValues);

        const canUseOnlineImage = !cp.ctx.hasImage() && cp.ctx.game && !!cp.ctx.game.settings && !!cp.ctx.game.settings.image;
        setUseOnlineImage(canUseOnlineImage);
      });
    }
  }, [cp, form]);

  useEffect(() => {
    if (form.formState?.isValid) {
      wp.setOnNext(() => {
        form.handleSubmit(cp.saveGame)();
      });
    } else {
      wp.setOnNext(undefined);
    }
  }, [cp, useOnlineImage, form, form.formState?.isValid]);


  return (

    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
    >
      <div>
        {cp.fetching && (
            <div>
              <Skeleton variant="rectangular" height={40} width={300} />
              <Skeleton variant="text" sx={{ fontSize: '1rem'  }} />
              <SpacerV />
              <Skeleton variant="rectangular" height={40} width={300} />
              <Skeleton variant="text" sx={{ fontSize: '1rem'  }} />
              <SpacerV />
              <Skeleton variant="text" sx={{ fontSize: '1rem'  }} />
              <Skeleton variant="text" sx={{ fontSize: '1rem'  }} />
              <Skeleton variant="text" sx={{ fontSize: '1rem'  }} />
              <SpacerV />
              <Skeleton variant="rectangular" sx={{borderRadius: '16px'}} height={200} width={250} />
            </div>
          )
        }

      {!cp.fetching && (
        <div>
          <YTextField
            fullWidth
            select
            name="difficulty"
            label="Difficulty"
            control={form.control}
            isRequired
          >
            {difficulties.map((d) => (
              <MenuItem key={d.label} value={d.label}>{d.label}</MenuItem>
            ))}
          </YTextField>

          <SpacerV size="20" />
          <Typography variant="body1">
            Please upload a high quality and high resolution image. The minimum resolution must be 1500px x 1500px square image. Rectangles are not accepted.
            Please use your own images to avoid copyright infrigement. Before the campaign is available, the image will be scanned for content and uniqueness.
          </Typography>
          <SpacerV size="10" />

          {useOnlineImage &&
            <Box sx={{ padding: 2, background: 'rgba(190, 194, 227, 0.15)', borderRadius: 2 }}>
              <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >

                <Grid item xs={9}>
                  <Box component="img"
                    src={cp.ctx.game.settings!.image}
                    alt={cp.ctx.game.name}
                    width="100%"
                    sx={{borderRadius: 2}}
                  />
                </Grid>

                <Grid item xs={3} sx={{textAlign: 'center'}}>
                  <IconButton
                    onClick={onImageRemove}
                    color="error"
                    sx={{align: 'right'}}
                  >
                    <Delete />
                  </IconButton>

                </Grid>
              </Grid>
            </Box>
          }

          {!useOnlineImage &&
            <Uploader isImage onSelect={onImageUpload} />
          }
        </div>
        )
      }
      </div>
    </Box>
  );
})

export default SetupGame;

